import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../App";
import getScreenWidth from "../../common/getScreenWidth";

export function Dimensions() {
    const { setResponsive, setScreenWidthHeight } = useContext(MainContext);

    const [width, setWindowWidth] = useState(0);
    const [height, setWindowHeight] = useState(0);

    useEffect(() => { 
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => 
        window.removeEventListener("resize",updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowWidth(width)
        setWindowHeight(height);
    }

    useEffect(() => {
        setResponsive(getScreenWidth(width));
        setScreenWidthHeight({width: width, height: height})
    },[width, height])
    
    return (<div></div>)
}