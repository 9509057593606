import { House } from 'react-bootstrap-icons';

export function Menu() {

    return (
            <div>
                <div className="row">
                    <div className="col-12">
                        {/*<div className="row">*/}
                        {/*    <div className="col-2">*/}
                        {/*        <House />*/}
                        {/*    </div>*/}
                        {/*    <div className="col-10">*/}
                        {/*        Home*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                         
                    </div>
                </div>
            </div>
        )
}