import React, { useContext } from 'react';
import { MainContext } from '../App';
import SideBar from './Layout/SideBar/SideBar';

export default function Layout({ children }) {
    const { responsive } = useContext(MainContext);
    return (
        <div>
            <SideBar />
            <div className="dashmain">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">{children}</div>
                    </div>            
                </div>        
            </div>
            <style jsx="true">{
                `
                    .dashmain {
                        padding-top: ${responsive ? '50px' : '0px'};

                    }
                `
                }
            </style>
        </div>
    )
}