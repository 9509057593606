import { useContext, useState } from "react";
import { MainContext } from "../../../App";
import { Menu } from "../../Menu/Menu";

export default function SideBar({
    children
}) {
    const { responsive } = useContext(MainContext)
    const [hamburgerOpen, setHamburgerOpen] = useState("");

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    return (
            <div>
                <div className="hamburger" onClick={toggleHamburger}>
                    <div className="hamburger-menu">
                        <div className="burger burger1"></div>
                        <div className="burger burger2"></div>
                        <div className="burger burger3"></div>
                    </div>
                </div>
                <div className="dashside">
                    <div className="row">
                        <div className="col-12">
                            <div className="logo mt-4">
                                <img src="./Images/web_builder_logo.jpg" alt="Logo webbuilder" className="logo-web-builder" />
                            </div>
                            <div className="divider mt-4 mb-2"></div>
                            <span className="title-website">Version WB</span>
                            <span className="text-small color-grey ms-2">v0.01</span>
                            <div className="divider mt-2 mb-2"></div>
                            <div className="menu mt-4">
                                <Menu />
                                {children}
                            </div>
                        </div>
                    </div>
            </div>
            <style jsx="true">{
                `
                    .dashside {
                        position: fixed;
                        left: ${responsive ? hamburgerOpen ? '0' : '-260px' : "0"};
                        padding-top: ${hamburgerOpen ? '50px' : '10px'};
                        width: 200px;
                        background-color: #FFF;
                        height: 100%;
                        padding-left: 10px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                    }
                    .dashmain {
                        margin-left: ${responsive ? '0px' : '200px'};
                    }
                    .hamburger {
                        position: fixed; 
                        z-index: 1; 
                        top: 0; 
                        left: 0;
                        display: ${responsive ? 'block' : 'none'};
                    }

                    .hamburger:hover {
                        cursor: pointer;
                    }

                    .burger1 {
                        transform: ${hamburgerOpen ? 'rotate(45deg)' : 'rotate(0)'};
                    }
                    .burger2 {
                        opacity: ${hamburgerOpen ? 0 : 1};
                    }
                    .burger3 {
                        transform:  ${hamburgerOpen ? 'rotate(-45deg)' : 'rotate(0)'};
                    }
                    .hamburger-menu {
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        justify-content: space-around;
                        flex-flow: column nowrap;
                        position: absolute;
                        top: 20px;
                        left: 15px;
                        z-index: 10;
                    }
                    .burger {
                        width: 2rem;
                        height: 0.25rem;
                        border-radius: 10px;
                        background-color: #000;
                        transform-origin: 1px;
                        transition: all 0.3s linear;
                    }
                `
            }

            </style>
            </div>
        )
}