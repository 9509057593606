import React, { useState } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import Dimensions from './components/Dimensions';
import Layout from "./components/Layout";
import './custom.css'

export const MainContext = React.createContext();

function App() {
    const [responsive, setResponsive] = useState(false);
    const [screenWidthHeight, setScreenWidthHeight] = useState({});

    return (
        <div className="App">
            <MainContext.Provider value={{
                responsive,
                setResponsive,
                screenWidthHeight,
                setScreenWidthHeight,
                }}
            >
                <Dimensions />
                <Layout>
                    <Route exact path='/' component={Home} />
                </Layout>
            </MainContext.Provider>
        </div >
            
    );
}
export default App;