import React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

export function Home() {

    const layouts = [
        { i: "a", x: 0, y: 0, w: 1, h: 2, static: true },
        { i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
        { i: "c", x: 4, y: 0, w: 1, h: 2 }
    ];
    return (
        <div>
            <div className="row ms-2 mt-4">
                <div className="col-md-6 col-12 mb-4">
                    <div className="blocks-wb text-center">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="font-bold">new test project</h1>
                            </div>
                        </div>
                        <div className="divider mt-2 mb-2"></div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <h4 className="font-bold">webbuilder by Schapie</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mb-4">
                    <div className="blocks-web-purple">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="font-bold color-white">.NET core</h1>
                                <h1 className="font-bold color-white">ReactJs</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <h1 className="color-dark">Coming soon!</h1>
                </div>
            </div>
            <ResponsiveGridLayout
                className="layout"
                layouts={layouts}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            >
                <div key="1">Drag me</div>
                <div key="2">Drag me</div>
                <div key="3">Drag me</div>
            </ResponsiveGridLayout>
        </div>
    )
}